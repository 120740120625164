<template>
    <div>
        <b-pagination
            class="custom-table-pagination justify-content-end"
            v-model="current"
            :total-rows="total"
            :per-page="toShow"
            hide-goto-end-buttons
            next-class="next-page"
            prev-class="prev-page"
            page-class="page"
        ></b-pagination>
    </div>
</template>

<script>
export default {
    name: "TablePagination",
    data() {
        return {
            current: this.value,
            pages: 1,
            toShow: parseInt(this.perPage),
        };
    },
    props: {
        value: {type: Number, default: 1},
        total: {type: Number, default: 0},
        perPage: {
            type: Number,
            default: () => {
                return this.$store.state.defaultPerPage || 15;
            },
        },
        pageChanged: {
            type: Function, default: () => {
            }
        },
        perPageChanged: {
            type: Function, default: () => {
            }
        },
    },
    watch: {
        current(item) {
            this.$emit("input", item);
            this.setPage(item);
        },
        perPage(show) {
            this.$emit("perPageChange", parseInt(show));
            this.setPerPage(parseInt(show));
        },
    },
    created() {
        this.calPageCount();
    },
    methods: {
        setPage(page) {
            this.current = page;
            this.pageChanged({current: this.current});
        },
        previous() {
            if (this.current > 0) this.setPage(this.current - 1);
        },
        next() {
            if (this.current < this.pages) this.setPage(this.current + 1);
        },
        setPerPage(perPage) {
            this.toShow = perPage;
            this.calPageCount();
            this.perPageChanged({currentPerPage: this.toShow});
        },
        calPageCount() {
            if (this.total > this.toShow) {
                this.pages = Math.round(this.total / this.toShow);
            } else {
                this.pages = 1;
                this.setPage(1);
            }
        },
    },
};
</script>
